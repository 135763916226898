<template>
  <div class="order-summary">
    <ul class="order-summary__list">
      <li v-for="(item, i) in list" :key="i" class="order-summary__item">
        <div class="order-summary__label standart-title">
          {{ getStringWithoutPrice(item.label) }}
        </div>
        <div class="order-summary__value standatr-text">
          {{ getStringWithoutPrice(item.value) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { isNaN } from 'lodash';
export default {
  name: 'OrderSummary',
  props: {
    type: {
      type: String,
      default: 'base',
    },
    details: {
      type: Object,
      required: true,
      // validator: val => val.main,
    },
  },
  computed: {
    pricelistMain() {
      if (this.type === 'base')
        return {
          label: this.$t('pricelist'),
          value: this.details.main.desc.replace(/SSL Certificate -/gi, ''),
        };
      else {
        const refactorObj = Object.assign({}, this.details);
        const newDetails = [];
        for (let [key, value] of Object.entries(refactorObj)) {
          newDetails.push({
            label: key,
            value: value.replace('RUB', '₽'),
          });
        }

        return newDetails.flat();
      }
    },
    pricelistAddons() {
      if (!this.details.addon) return []; // аренда физических серверов - прилетает без аддона
      if (this.type === 'base')
        return this.details.addon.map(addon => ({
          label: addon.desc,
          value: this.getValueAsLocalString(addon),
        }));
      else return [];
    },
    pricelistOther() {
      if (this.type === 'base')
        return this.details.other
          ? this.details.other
              .map(addon => ({
                label: addon.label,
                value: this.getValueAsLocalString(addon),
              }))
              .filter(x => x.label !== 'Адрес Plesk Сервера')
          : [];
      else return [];
    },
    list() {
      if (this.type === 'base')
        return [this.pricelistMain, ...this.pricelistAddons, ...this.pricelistOther];
      else return this.pricelistMain;
    },
  },
  methods: {
    getValueAsLocalString(addon) {
      if (addon.textAboutTairiff && addon.textAboutTairiff.includes('RUB'))
        return addon.textAboutTairiff.replace('RUB', '₽');
      if (addon.enumerationitem_name) return addon.enumerationitem_name;
      const value = addon.value;
      if (['on', 'off'].includes(value)) return this.$t(`checkbox.${value}`);
      else if (!isNaN(parseInt(value))) {
        const measure = addon.measure ? ` ${addon.measure}` : '';
        let str = value + measure;
        if (addon.addonvalue && parseInt(addon.addonvalue)) {
          str += ` (${this.$t('additional')} ${addon.addonvalue + measure})`;
        }
        return str;
      } else return value || '-';
    },
    getStringWithoutPrice(str) {
      const regex = /\(.*\d*₽.*\)/gm;
      if (str.search(regex)) return str.replace(regex, '').trim();
      else return str;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "pricelist": "Тариф",
    "additional": "из них дополнительно",
    "checkbox": {
      "on": "Вкл",
      "off": "Выкл"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.order-summary {
  &__list {
    no-style-list();
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    & + & {
      margin-top: 1.25rem;
      +breakpoint(sm-and-up) {
        margin-top: 0.25rem;
      }
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 1.5rem;
    }
  }
}
</style>
